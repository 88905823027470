/* You can add global styles to this file, and also import other style files */
/* Jan 25 */
@import "variables";
// .ant-card {
//   border-radius: $zyephrCardRadius !important;
//   box-shadow: $zyephrShadow;
//   .ant-card-actions {
//     border-radius: 0 0 $zyephrCardRadius $zyephrCardRadius !important;
//   }
//   .ant-card-head {
//     border-radius: $zyephrCardRadius $zyephrCardRadius 0 0 !important;
//     background: rgb(247 121 36 / 40%);
//     .ant-card-extra {
//       a {
//         color: $zyephrSecondaryColor;
//       }
//     }
//     .ant-card-head-title {
//       font-weight: bold;
//     }
//   }
// }

.ant-table {
  .ant-table-thead > tr > th {
    //background: rgb(247 121 36 / 40%);
  }
  .ant-table-thead > tr > th:first-child {
    border-radius: $zyephrCardRadius 0 0 0 !important;
  }
  .ant-table-thead > tr > th:last-child {
    border-radius: 0 $zyephrCardRadius 0 0 !important;
  }
  .ant-table-thead > tr > th:only-child {
    border-radius: $zyephrCardRadius $zyephrCardRadius 0 0 !important;
  }
  .ant-table-thead {
    .ant-table-cell {
      font-weight: bold;
    }
  }
}

.ant-card:hover{
  box-shadow: $zyephrShadowHover;
}
.ant-card.no-color {
  .ant-card-head {
    background: #ffffff;
  }
}


.ant-collapse {
  border-radius: $zyephrCardRadius !important;
  box-shadow: $zyephrShadow;
  .ant-collapse-actions {
    border-radius: 0 0 $zyephrCardRadius $zyephrCardRadius !important;
  }
  .ant-collapse-header {
    // border-radius: $zyephrCardRadius $zyephrCardRadius 0 0 !important;
    background: rgb(247 121 36 / 40%);
    .ant-collapse-extra {
      a {
        color: $zyephrSecondaryColor;
      }
    }
    font-weight: bold;

  }
}

.ant-collapse:hover{
  box-shadow: $zyephrShadowHover;
}
.ant-collapse.no-color {
  .ant-collapse-head {
    background: #ffffff;
  }
}

.zyephr-shadow {
  box-shadow: $zyephrShadow;
}
.zyephr-shadow:hover {
  box-shadow: $zyephrShadowHover;
}



/* End Jan 25 */


.topheadingifbutton {
  position: relative;
  margin-bottom: 20px;
  clear: both;
  overflow: hidden;
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 15px;
  h3 {
    float: left;
    font-size: 22px;
    letter-spacing: 0.2px;
  }
}

//status active/in-active
.active {
  background-color: green;
}
.inactive {
  background-color: red;
}
.status {
  width: 12px;
  height: 12px;
  display: block;
  // float: right;
  margin-left: 5px;
}
// Dynamic Margin & Padding class:
$sizes: 12;

@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 0.25rem;
    /* margin #{$margin} */
    .m#{$i}  {margin: $margin;}
    .ml#{$i} {margin-left: $margin;}
    .mr#{$i} {margin-right: $margin;}
    .mt#{$i} {margin-top: $margin;}
    .mb#{$i} {margin-bottom: $margin;}
    .mx#{$i} {margin-left: $margin; margin-right: $margin;}
    .my#{$i} {margin-top: $margin; margin-bottom: $margin;}
  }
}
@include margin-classes;


@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    .p#{$i} {padding: $padding;}
    .pl#{$i} {padding-left: $padding;}
    .pr#{$i} {padding-right: $padding;}
    .pt#{$i} {padding-top: $padding;}
    .pb#{$i} {padding-bottom: $padding;}
    .px#{$i} {padding-left: $padding; padding-right: $padding;}
    .py#{$i} {padding-top: $padding; padding-bottom: $padding;}
  }
}
@include padding-classes;

.addUpdateButton{
  float: right;
}

.ant-tag-checkable {
  border: 1px solid;
}

.fullwidth{
  width: 100%;
}

.width-100{
  width: 100%;
}

.text-right{
  text-align: right;
}

.text-center{
  text-align: center;
}

.width100{
  width: 100%;
}

